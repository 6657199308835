import React from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import AXIOS from '../../../axios-config';
import AdminLayout from '../../components/admin/adminlayout';
import Checkbox from '../../components/checkbox';
import Spinner from '../../components/spinner';
import cogoToast from 'cogo-toast';
import Roles from '../../components/admin/roles';

class Login extends React.Component {
  state = {
    isLoading: false
  };

  initialValues = {
    username: '',
    password: '',
    roles: []
  };

  validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Please enter your username')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    password: Yup.string()
      .required('Please enter your password')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    roles: Yup.array()
      .required('Please select at least one role')
  });

  handleSubmit = (values, { setSubmitting, resetForm }) => {
    this.setState({
      isLoading: true
    });
    setSubmitting(true);

    AXIOS.post('/api/users', values)
      .then(() => {
        this.setState({
          isLoading: false
        });
        resetForm();
        setSubmitting(false);
        cogoToast.success('Your form has been submitted successfully', { position: 'top-center' });
      })
      .catch((err) => {
        this.setState({
          isLoading: false
        });
        setSubmitting(false);
        cogoToast.error("Your form wasn't submitted", { position: 'top-center' });
      });
  };
  render() {
    return (
      <AdminLayout>
        <div className="form">
          <div className="container login">
            <div className="form-header">
              <h1>Register User</h1>
            </div>
            <Formik
              initialValues={this.initialValues}
              validationSchema={this.validationSchema}
              onSubmit={this.handleSubmit}
            >
              {({ values, errors, touched, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                  <div className="input__group">
                    <label htmlFor="username">Username</label>
                    <Field type="text" name="username" />
                  </div>
                  <div className="input__group">
                    <label htmlFor="password">Password</label>
                    <Field type="password" name="password" />
                  </div>
                  <div className="input__group">
                    <p>Select Roles</p>
                    <Checkbox name="roles" value={Roles.admin} />
                    <Checkbox name="roles" value={Roles.press}/>
                    <Checkbox name="roles" value={Roles.westernRegion} />
                    <Checkbox name="roles" value={Roles.experiences} />
                    <Checkbox name="roles" value={Roles.bmwGroupEventEastCoast} />
                    <Checkbox name="roles" value={Roles.bmwGroupEventWestCoast} />
                    <Checkbox name="roles" value={Roles.miniGroupEventEastCoast} />
                    <Checkbox name="roles" value={Roles.miniGroupEventWestCoast} />
                    <Checkbox name="roles" value={Roles.motoTwistedTour}/>
                    <Checkbox name="roles" value={Roles.motoAdventureTour}/>
                    <Checkbox name="roles" value={Roles.minisc}/>
                    <Checkbox name="roles" value={Roles.IndianapolisMotorSpeedway}/>
                    <Checkbox name="roles" value={Roles.VirginiaInternationalRaceway}/>
                    <Checkbox name="roles" value={Roles.Other}/>
                    <Checkbox name="roles" value={Roles.Mpact}/>
                    <Checkbox name="roles" value={Roles.PlannerRegistrations}/>
                    <Checkbox name="roles" value={Roles.CarAndCookoutTable}/>
                    <Checkbox name="roles" value={Roles.SouthOCCC}/>
                  </div>
                  <button className="btn btn-blue" type="submit" disabled={isSubmitting}>
                    {this.state.isLoading ? <Spinner /> : 'Submit'}
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </AdminLayout>
    );
  }
}
export default Login;
