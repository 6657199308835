import React from 'react';
import AXIOS from '../../../axios-config';
import '../../styles/main.scss';
import AdminLayout from '../../components/admin/adminlayout';
import cogoToast from 'cogo-toast';
import { isLoggedIn } from '../../services/auth';
import Roles from '../../components/admin/roles';
import { showErrorToast } from "../../helpers/toast";

class Users extends React.Component {
  state = {
    submissions: []
  };

  componentDidMount() {
    AXIOS.get('/api/users')
      .then((response) => {
        this.setState({
          submissions: response.data
        });
       
      })
      .catch((err) => {
        showErrorToast(err)
      });
  }

  deleteSubmission(item, id) {
    const newState = this.state.submissions.slice();
    if (newState.indexOf(item) > -1) {
      newState.splice(newState.indexOf(item), 1);
      this.setState({ submissions: newState });
    }
    AXIOS.delete(`/api/users/${id}`)
      .then((response) => {
        cogoToast.success('Submission has been deleted successfully', { position: 'top-right' });
      })
      .catch((err) => {
        cogoToast.error('Error when deleting submission', { position: 'top-right' });
      });
  }

  render() {
    const { submissions } = this.state;
    return (
      <AdminLayout>
        <div className="form">
          <div className="container">
            <div className="form-header">
              <h1>Users</h1>
            </div>
            <table className="form-submission">
              <thead>
                <tr>
                  <th>Username</th>
                  <th>Roles</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!submissions.length && (
                  <tr className="no-submissions">
                    <td colSpan="12">There is no form submission yet</td>
                  </tr>
                )}
                {submissions.map((submission) => {
                  return (
                    <tr key={submission._id}>
                      <td>{submission.username}</td>
                      <td>{submission.roles.map((role, i) => {
                        return (
                          <p key={i}>{role}</p>
                        )
                      })}</td>
                      {isLoggedIn([ Roles.admin ]) && (
                        <td>
                          <button
                            onClick={this.deleteSubmission.bind(this, submission, submission._id)}
                            className="btn-small btn-red"
                          >
                            Delete
                          </button>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </AdminLayout>
    );
  }
}

export default Users;
