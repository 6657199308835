import React from 'react';
import AXIOS from '../../../axios-config';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import Moment from 'react-moment';
import "../../styles/main.scss"
import AdminLayout from '../../components/admin/adminlayout';
import cogoToast from 'cogo-toast';
import { isLoggedIn } from '../../services/auth';
import Roles from '../../components/admin/roles';
import { showErrorToast } from "../../helpers/toast";

class Experiences extends React.Component {
  state = {
    submissions: []
  };

  componentDidMount() {
    AXIOS.get('/api/experiences')
      .then((response) => {
        this.setState({
          submissions: response.data
        });
      })
      .catch((err) => {
        showErrorToast(err)
      });

  }

  deleteSubmission(item, id) {
    const newState = this.state.submissions.slice();
    if (newState.indexOf(item) > -1) {
      newState.splice(newState.indexOf(item), 1);
      this.setState({ submissions: newState });
    }
    AXIOS.delete(`/api/experiences/${id}`)
      .then((response) => {
        cogoToast.success('Submission has been deleted successfully', {position: 'top-right'})
      }).catch((err) => {
        cogoToast.error("Error when deleting submission",{ position: 'top-right'})
      })
  }

  handleExportToCSV = () => {
    const csvConfig = mkConfig({ 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showTitle: true,
      title: 'Experiences Form \n',
      showColumnHeaders: true,
      useTextFile: false,
      useBom: true,
      columnHeaders: ["_id","fullname","email","date"]
    });

    const csv = generateCsv(csvConfig)(this.state.submissions);
    download(csvConfig)(csv);
  };

  render() {
    const { submissions } = this.state;

    return (
      <AdminLayout>
        <div className="form">
          <div className="container">
            <div className="form-header">
              <h1>Experiences Form</h1>
              <button className="btn btn-blue" onClick={this.handleExportToCSV}>
                Export CSV
              </button>
            </div>
            <table className="form-submission">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Full Name</th>
                  <th>Email Address</th>
                  {
                    isLoggedIn([Roles.admin]) && (
                      <th>Action</th>
                    )
                  }
                </tr>
              </thead>
              <tbody>
                {!submissions.length && (
                  <tr className="no-submissions">
                    <td colSpan="12">There is no form submission yet</td>
                  </tr>
                )}
                {submissions.map((submission) => {
                  return (
                    <tr key={submission._id}>
                      <td>
                        <Moment format="YYYY-MM-DD HH:mm">{submission.date}</Moment>
                      </td>
                      <td>{submission.fullname}</td>
                      <td>{submission.email}</td>
                      {isLoggedIn([ Roles.admin ]) && (
                        <td>
                          <button
                            onClick={this.deleteSubmission.bind(this, submission, submission._id)}
                            className="btn-small btn-red"
                          >
                            Delete
                          </button>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </AdminLayout>
    );
  }
}

export default Experiences;
