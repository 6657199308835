import React from 'react';
import AXIOS from '../../../axios-config';
import {  mkConfig, generateCsv, download } from 'export-to-csv';
import Moment from 'react-moment';
import '../../styles/main.scss';
import AdminLayout from './adminlayout';
import cogoToast from 'cogo-toast';
import { isLoggedIn } from '../../services/auth';
import Roles from './roles';
import { showErrorToast } from "../../helpers/toast";



class PgaWest extends React.Component {
  state = {
    submissions: []
  };

componentDidMount() {
  AXIOS.get('/api/pgawest-form')
    .then((response) => {
      this.setState({
        submissions: response.data
      });
    })
    .catch((err) => {
      showErrorToast(err)
    });

}

  deleteSubmission = (item, id) => {
    const newState = this.state.submissions.slice();
    if (newState.indexOf(item) > -1) {
      newState.splice(newState.indexOf(item), 1);
      this.setState({ submissions: newState });
    }
    AXIOS.delete(`/api/pgawest-form/${id}`)
      .then((response) => {
        cogoToast.success('Submission has been deleted successfully', { position: 'top-right' });
      })
      .catch((err) => {
        cogoToast.error('Error when deleting submission', { position: 'top-right' });
      });
  }

  handleExportToCSV = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'PGA West Form\n',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true
    });

    const csv = generateCsv(csvConfig)(this.state.submissions);
    download(csvConfig)(csv);
  };

  render() {
    const { submissions } = this.state;
    return (
      <AdminLayout>
        <div className="form">
          <div className="container">
            <div className="form-header">
              <h1>PGA West</h1>
              {submissions.length > 0 && (
                <button className="btn btn-blue" onClick={this.handleExportToCSV}>
                  Export CSV
                </button>
              )}
            </div>
            <table className="form-submission">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Address</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Zipcode</th>
                  <th>Phone Number</th>
                  {isLoggedIn([ Roles.admin ]) && <th>Action</th>}
                </tr>
              </thead>
              <tbody>
                {submissions.length === 0 && (
                  <tr className="no-submissions">
                    <td colSpan="12">There is no form submission yet</td>
                  </tr>
                )}
                {submissions && submissions.map && submissions.map((submission) => {
                  return (
                    <tr key={submission._id}>
                      <td>
                        <Moment format="YYYY-MM-DD HH:mm">{submission.date}</Moment>
                      </td>
                      <td>{submission.firstname}</td>
                      <td>{submission.lastname}</td>
                      <td>{submission.email}</td>
                      <td>{submission.address}</td>
                      <td>{submission.city}</td>
                      <td>{submission.state}</td>
                      <td>{submission.zipcode}</td>
                      <td>{submission.phone}</td>
                      {isLoggedIn([ Roles.admin ]) && (
                        <td>
                          <button
                            onClick={() => {
                              this.deleteSubmission(submission, submission._id)
                            }}
                            className="btn-small btn-red"
                          >
                            Delete
                          </button>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </AdminLayout>
    );
  }
}

export default PgaWest;