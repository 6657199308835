import React from "react";
import {  Field } from "formik";

function Checkbox(props) {
  return (
    <div className="checkbox__group">
      <Field type="checkbox" name={props.name} value={props.value} />
      <label htmlFor="spartanburg">{props.value}</label>
  </div>
  );
}

export default Checkbox;