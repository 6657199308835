import React from "react";
import { Link } from "gatsby";
import AXIOS from "../../../../axios-config";
import {  mkConfig, generateCsv, download } from 'export-to-csv';
import Moment from "react-moment";
import "../../../styles/main.scss";
import AdminLayout from "../adminlayout";
import cogoToast from "cogo-toast";
import { isLoggedIn } from "../../../services/auth";
import Roles from "../roles";
import { showErrorToast } from "../../../helpers/toast";

class PlannerRegistrationTable2 extends React.Component {
  state = {
    submissions: [],
  };

  componentDidMount() {
    AXIOS.get("/api/planner-registration-form")
      .then((response) => {
        this.setState({
          submissions: response.data,
        });
      })
      .catch((err) => {
        showErrorToast(err)
      });
  }

  deleteSubmission(item, id) {
    const newState = this.state.submissions.slice();
    if (newState.indexOf(item) > -1) {
      newState.splice(newState.indexOf(item), 1);
      this.setState({ submissions: newState });
    }
    AXIOS.delete(`/api/planner-registration-form/${id}`)
      .then((response) => {
        cogoToast.success("Submission has been deleted successfully", {
          position: "top-right",
        });
      })
      .catch((err) => {
        cogoToast.error("Error when deleting submission", {
          position: "top-right",
        });
      });
  }

  handleExportToCSV = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: "Planner Registration - Form\n",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    });


    const { submissions } = this.state;

    const PlannerRegistrationSubmissions = submissions;

    const data = PlannerRegistrationSubmissions.map((submission) => {
      const quantityOfEvents = (submission.average_quantity_of_events && submission.average_quantity_of_events.toString()) || "" 
      const receiveEmails = (submission.receive_emails && submission.receive_emails.toString()) || "" 
      
      return {
        submitted_date: submission.date,
        firstname: submission.firstname,
        lastname: submission.lastname,
        email: submission.email,
        bmw_salesperson: submission.bmw_salesperson,
        company_address: submission.company_address,
        company_name: submission.company_name,
        designation_or_certificate_or_licence: submission.designation_or_certificate_or_licence,
        designation_or_certificate_or_licence_other: submission.designation_or_certificate_or_licence_other && submission.designation_or_certificate_or_licence_other,
        mobile_phone: submission.mobile_phone,
        office_phone: submission.office_phone,
        title: submission.title,
        city: submission.city,
        state: submission.state,
        zip: submission.zip,
        receive_emails: receiveEmails,
        IATA: submission.IATA,
        average_annual_billings: submission.average_annual_billings,
        average_quantity_of_events: quantityOfEvents,
      };
    });

    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  render() {
    const { submissions } = this.state;

    const PlannerRegistrationSubmissions = submissions;
    return (
      <AdminLayout>
        <div className="form">
          <div className="container">
            <div className="form-header">
              <h1>Planner Registration Form</h1>
              {PlannerRegistrationSubmissions.length> 0 && (
                <button className="btn btn-blue" onClick={this.handleExportToCSV}>
                  Export CSV
                </button>
              )}
            </div>
            <table className="form-submission">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email Address</th>
                  <th>BMW Sales Person</th>
                  <th>Company Address</th>
                  <th>Company Name</th>
                  <th>Designation or Certificate or Licence</th>
                  <th>Designation or Certificate or Licence Other</th>
                  <th>IATA</th>
                  <th>Average annual billings</th>
                  <th>Average quantity of events</th>
                  <th>Mobile Phone</th>
                  <th>Office Phone</th>
                  <th>Title</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Zip</th>
                  <th>Receive Emails</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!PlannerRegistrationSubmissions.length && (
                  <tr className="no-submissions">
                    <td colSpan="12">There is no form submission yet</td>
                  </tr>
                )}
                {PlannerRegistrationSubmissions.map((submission) => {
                  return (
                    <tr key={submission._id}>
                      <td>
                        <Moment format="YYYY-MM-DD HH:mm">
                          {submission.date}
                        </Moment>
                      </td>
                      <td>{submission.firstname}</td>
                      <td>{submission.lastname}</td>
                      <td>{submission.email}</td>
                      <td>{submission.bmw_salesperson}</td>
                      <td>{submission.company_address}</td>
                      <td>{submission.company_name}</td>
                      <td>{submission.designation_or_certificate_or_licence}</td>
                      <td>{submission.designation_or_certificate_or_licence_other}</td>
                      <td>{submission.IATA}</td>
                      <td>{submission.average_annual_billings}</td>
                      <td>{submission.average_quantity_of_events}</td>
                      <td>{submission.mobile_phone}</td>
                      <td>{submission.office_phone}</td>
                      <td>{submission.title}</td>
                      <td>{submission.city}</td>
                      <td>{submission.state}</td>
                      <td>{submission.zip}</td>
                      <td>{submission.receive_emails.toString()}</td>
                      <td>
                        <Link to={`/admin/plannerregistration/detail/${submission._id}`}>
                          <button className="btn-small btn-blue">View</button>
                        </Link>
                        {isLoggedIn([Roles.admin]) && (
                          <button
                            onClick={this.deleteSubmission.bind(
                              this,
                              submission,
                              submission._id
                            )}
                            className="btn-small btn-red"
                          >
                            Delete
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </AdminLayout>
    );
  }
}

export default PlannerRegistrationTable2;
