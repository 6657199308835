import React from 'react';
import AXIOS from '../../../axios-config';
import Moment from 'react-moment';
import AdminLayout from './adminlayout'
import { showErrorToast } from "../../helpers/toast";


class SouthOCCCDetail extends React.Component {
  state = {
    submissions: []
  };

  componentDidMount() {

    const id = this.props.id;

    AXIOS.get(`/api/southoccc-form/${id}`)
      .then((response) => {
        this.setState({
          submissions: response.data
        });
      })
      .catch((err) => {
        showErrorToast(err)
      });
  }

  render() {
    const { firstname, lastname, email, phone_number, address, desired_class, discount_code, receive_emails, date, _id } = this.state.submissions;
    return (
      <AdminLayout>
        <div className="submission">
        <div className="container">
          <h1> South OCCC Submission (ID - {_id})</h1>
          <div className="submission__content">
            <table className="form-submission">
              <thead>
                <tr>
                  <th>Fields</th>
                  <th>Information</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Date</td>
                  <td>
                    <Moment format="YYYY-MM-DD HH:mm">{date}</Moment>
                  </td>
                </tr>
                <tr>
                  <td>First Name</td>
                  <td>{firstname}</td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>{lastname}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{email}</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>{address}</td>
                </tr>
                <tr>
                  <td>Phone Number</td>
                  <td>{phone_number}</td>
                </tr>
                <tr>
                  <td>Desired Class</td>
                  <td>{desired_class}</td>
                </tr>
                <tr>
                  <td>Discount Code</td>
                  <td>{discount_code}</td>
                </tr>
                <tr>
                  <td>Receive Emails</td>
                  <td>{receive_emails.toString()}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </AdminLayout>
    );
  }
}

export default SouthOCCCDetail;
