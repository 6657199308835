import React from 'react';
import { Link } from 'gatsby';
import AXIOS from '../../../../axios-config';
import {  mkConfig, generateCsv, download } from 'export-to-csv';
import Moment from 'react-moment';
import '../../../styles/main.scss';
import AdminLayout from '../adminlayout';
import cogoToast from 'cogo-toast';
import { isLoggedIn } from '../../../services/auth';
import Roles from '../roles';
import { showErrorToast } from "../../../helpers/toast";

class GroupEventWestCoast extends React.Component {
  state = {
    submissions: []
  };

  componentDidMount() {
    AXIOS.get('/api/groupevent-form')
      .then((response) => {
        this.setState({
          submissions: response.data
        });
      })
      .catch((err) => {
        showErrorToast(err)
      });
  }

  deleteSubmission(item, id) {
    const newState = this.state.submissions.slice();
    if (newState.indexOf(item) > -1) {
      newState.splice(newState.indexOf(item), 1);
      this.setState({ submissions: newState });
    }
    AXIOS.delete(`/api/groupevent-form/${id}`)
      .then((response) => {
        cogoToast.success('Submission has been deleted successfully', {position: 'top-right'})
      }).catch((err) => {
        cogoToast.error("Error when deleting submission",{ position: 'top-right'})
      })
  }

  handleExportToCSVThermal = () => {
    const csvConfig = mkConfig({ 
      fieldSeparator: ',',
      filename: 'groupevents',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Group Events Form - Thermal, CA\n',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true
    });

    const thermal_data = this.state.submissions.filter(function(submission){
      return submission.selectedLocation.includes('Thermal, CA')
      });

    const data = thermal_data.map((submission) => {
        return {
          firstname: submission.firstname,
          lastname: submission.lastname,
          organization: submission.organization,
          job_title: submission.job_title,
          company_website: submission.company_website,
          zipcode: submission.zipcode,
          email: submission.email,
          address: submission.address,
          city: submission.city,
          state: submission.state,
          phone: submission.phone,
          attendees: submission.attendees,
          event_type: submission.event_type,
          event_date: submission.event_date,
          locationOne: 'Thermal, CA',
          notes: submission.notes,
          submitted_date: submission.date,
        };
      });



    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  render() {
    const { submissions } = this.state;

    const thermalSubmissions = submissions.filter((submission) => {
      return submission.selectedLocation.includes("Thermal, CA");
    })

    return (
      <AdminLayout>
        <div className="form">
          <div className="container">
            <div className="form-header">
              <h1>Group Event West Coast</h1>
              {thermalSubmissions.length > 0 && (
              <button className="btn btn-blue" onClick={this.handleExportToCSVThermal}>
                Export CSV
              </button>
              )}
            </div>
            <table className="form-submission">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Organization</th>
                  <th>Email Address</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!thermalSubmissions.length && (
                  <tr className="no-submissions">
                    <td colSpan="12">There is no form submission yet</td>
                  </tr>
                )}
                {thermalSubmissions.map((submission) => {
                  return (
                    <tr key={submission._id}>
                      <td>
                        <Moment format="YYYY-MM-DD HH:mm">{submission.date}</Moment>
                      </td>
                      <td>{submission.firstname}</td>
                      <td>{submission.lastname}</td>
                      <td>{submission.organization}</td>
                      <td>{submission.email}</td>
                      <td>
                        <Link to={`/admin/groupevents/detail/${submission._id}`}><button className="btn-small btn-blue">View</button></Link>
                        {
                          isLoggedIn([Roles.admin]) && (
                            <button onClick={this.deleteSubmission.bind(this, submission, submission._id)} className="btn-small btn-red">Delete</button>
                          )
                        }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </AdminLayout>
    );
  }
}

export default GroupEventWestCoast;
