export default {
  admin: "Admin",
  press: "Press",
  experiences: "Experiences",
  westernRegion: "Western Region",
  bmwGroupEventWestCoast: "BMW Group Events West Coast",
  bmwGroupEventEastCoast: "BMW Group Events East Coast",
  miniGroupEventWestCoast: "MINI Group Events West Coast",
  miniGroupEventEastCoast: "MINI Group Events East Coast",
  motoTwistedTour: "Moto Twisted Tour",
  motoAdventureTour: "Moto Adventure Tour",
  r18SmokyMountainsTour: "R18 Smoky Mountains Tour",
  OnRoadTraining: "On-Road Training Session",
  OffRoadTraining: "Off-Road Training Session",
  minisc: "Mini SC",
  IndianapolisMotorSpeedway: "Indianapolis Motor Speedway",
  VirginiaInternationalRaceway: "Virginia International Raceway",
  Other: "Other",
  Mpact: "Mpact",
  PlannerRegistrations: "Planner Registrations",
  CarAndCookoutTable: "Car And Cookout",
  SouthOCCC: "South OCCC"
};
