import React from "react";
import AXIOS from "../../../axios-config";
import Moment from "react-moment";
import AdminLayout from "./adminlayout";
import { showErrorToast } from "../../helpers/toast";

class PlannerRegistrationDetail extends React.Component {
  state = {
    submissions: [],
  };

  componentDidMount() {
    const id = this.props.id;

    AXIOS.get(`/api/planner-registration-form/${id}`)
      .then((response) => {
        this.setState({
          submissions: response.data,
        });
      })
      .catch((err) => {
        showErrorToast(err)
      });
  }

  render() {
    const {
      firstname,
      lastname,
      email,
      bmw_salesperson,
      company_address,
      company_name,
      designation_or_certificate_or_licence,
      designation_or_certificate_or_licence_other,
      mobile_phone,
      office_phone,
      receive_emails,
      title,
      city,
      state,
      zip,
      IATA,
      average_annual_billings,
      average_quantity_of_events,
      date,
      _id,
    } = this.state.submissions;
    return (
      <AdminLayout>
        <div className="submission">
          <div className="container">
            <h1> Planner Registration Submission (ID - {_id})</h1>
            <div className="submission__content">
              <table className="form-submission">
                <thead>
                  <tr>
                    <th>Fields</th>
                    <th>Information</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Date</td>
                    <td>
                      <Moment format="YYYY-MM-DD HH:mm">{date}</Moment>
                    </td>
                  </tr>
                  <tr>
                    <td>First Name</td>
                    <td>{firstname}</td>
                  </tr>
                  <tr>
                    <td>Last Name</td>
                    <td>{lastname}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{email}</td>
                  </tr>
                  <tr>
                    <td>BMW Sales Person</td>
                    <td>{bmw_salesperson}</td>
                  </tr>
                  <tr>
                    <td>Company Address</td>
                    <td>{company_address}</td>
                  </tr>
                  <tr>
                    <td>Company Name</td>
                    <td>{company_name}</td>
                  </tr>
                  <tr>
                    <td>Designation or Certificate or Licence</td>
                    <td>{designation_or_certificate_or_licence}</td>
                  </tr>
                  <tr>
                    <td>Designation or Certificate or Licence Other</td>
                    <td>{designation_or_certificate_or_licence_other}</td>
                  </tr>
                  <tr>
                    <td>Mobile Phone</td>
                    <td>{mobile_phone}</td>
                  </tr>
                  <tr>
                    <td>Office Phone</td>
                    <td>{office_phone}</td>
                  </tr>
                  <tr>
                    <td>Title</td>
                    <td>{title}</td>
                  </tr>
                  <tr>
                    <td>City</td>
                    <td>{city}</td>
                  </tr>
                  <tr>
                    <td>State</td>
                    <td>{state}</td>
                  </tr>
                  <tr>
                    <td>Zip</td>
                    <td>{zip}</td>
                  </tr>
                  <tr>
                    <td>Receive Emails</td>
                    <td>{receive_emails.toString()}</td>
                  </tr>
                  <tr>
                    <td>IATA</td>
                    <td>{IATA}</td>
                  </tr>
                  <tr>
                    <td>Average annual billings</td>
                    <td>{average_annual_billings}</td>
                  </tr>
                  <tr>
                    <td>Average quantity of events</td>
                    <td>{average_quantity_of_events.toString()}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </AdminLayout>
    );
  }
}

export default PlannerRegistrationDetail;
