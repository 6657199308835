import React from "react";
import { Link } from "gatsby";
import AXIOS from "../../../../axios-config";
import {  mkConfig, generateCsv, download } from 'export-to-csv';
import Moment from "react-moment";
import "../../../styles/main.scss";
import AdminLayout from "../../../components/admin/adminlayout";
import cogoToast from "cogo-toast";
import { isLoggedIn } from "../../../services/auth";
import Roles from "../../../components/admin/roles";
import { showErrorToast } from "../../../helpers/toast";

class VirginiaInternationalRaceway extends React.Component {
  state = {
    submissions: [],
  };

  componentDidMount() {
    AXIOS.get("/api/mschool-ontour-form")
      .then((response) => {
        this.setState({
          submissions: response.data,
        });
      })
      .catch((err) => {
        showErrorToast(err)
      });
  }

  deleteSubmission(item, id) {
    const newState = this.state.submissions.slice();
    if (newState.indexOf(item) > -1) {
      newState.splice(newState.indexOf(item), 1);
      this.setState({ submissions: newState });
    }
    AXIOS.delete(`/api/mschool-ontour-form/${id}`)
      .then((response) => {
        cogoToast.success("Submission has been deleted successfully", {
          position: "top-right",
        });
      })
      .catch((err) => {
        cogoToast.error("Error when deleting submission", {
          position: "top-right",
        });
      });
  }

  handleExportToCSV = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title:
        "Advanced M School On-Tour (Virginia International Raceway) Event Forms\n",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    });

    const data = this.state.submissions.map((submission) => {
      const VirginiaInternationalRaceway = submission.selectedTracks.indexOf(
        "Virginia International Raceway"
      );
      const IndianapolisMotorSpeedway = submission.selectedTracks.indexOf(
        "Indianapolis Motor Speedway"
      );
      const Other = submission.selectedTracks.indexOf("Other");

      return {
        submitted_date: submission.date,
        fullname: submission.fullname,
        email: submission.email,
        VirginiaInternationalRaceway:
          VirginiaInternationalRaceway !== -1
            ? submission.selectedTracks[VirginiaInternationalRaceway]
            : "",
        IndianapolisMotorSpeedway:
          IndianapolisMotorSpeedway !== -1
            ? submission.selectedTracks[IndianapolisMotorSpeedway]
            : "",
        Other: Other !== -1 ? submission.selectedTracks[Other] : "",
      };
    });

    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  render() {
    const { submissions } = this.state;
    const virginiaInternationalRacewaySubmissions = submissions.filter(
      (submission) => {
        return submission.selectedTracks.includes(
          "Virginia International Raceway"
        );
      }
    );

    return (
      <AdminLayout>
        <div className="form">
          <div className="container">
            <div className="form-header">
              <h1>
                Advanced M School On-Tour (Virginia International Raceway) Event
                Forms
              </h1>
              {virginiaInternationalRacewaySubmissions.length > 0 && (
                <button className="btn btn-blue" onClick={this.handleExportToCSV}>
                  Export CSV
                </button>
              )}
            </div>
            <table className="form-submission">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Full Name</th>
                  <th>Email Address</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!virginiaInternationalRacewaySubmissions.length && (
                  <tr className="no-submissions">
                    <td colSpan="12">There is no form submission yet</td>
                  </tr>
                )}
                {virginiaInternationalRacewaySubmissions.map((submission) => {
                  return (
                    <tr key={submission._id}>
                      <td>
                        <Moment format="YYYY-MM-DD HH:mm">
                          {submission.date}
                        </Moment>
                      </td>
                      <td>{submission.fullname}</td>
                      <td>{submission.email}</td>
                      <td>
                        <Link
                          to={`/admin/mschoolontourevents/detail/${submission._id}`}
                        >
                          <button className="btn-small btn-blue">View</button>
                        </Link>
                        {isLoggedIn([Roles.admin]) && (
                          <button
                            onClick={this.deleteSubmission.bind(
                              this,
                              submission,
                              submission._id
                            )}
                            className="btn-small btn-red"
                          >
                            Delete
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </AdminLayout>
    );
  }
}

export default VirginiaInternationalRaceway;
