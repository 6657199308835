export const isBrowser = () => typeof window !== "undefined";

export const getAuthUser = () => 
  isBrowser() && JSON.parse(window.localStorage.getItem("authUser"))
 
export const setAuthUser = (authUser) => 
  window.localStorage.setItem("authUser", JSON.stringify(authUser))

export const setToken = (userToken) => 
  window.localStorage.setItem("token", JSON.stringify(userToken))

export const isLoggedIn = (roles) => {
  const loggedInUser = getAuthUser();

  if (!loggedInUser) return false;

  if (loggedInUser.user.roles.indexOf('Admin') > -1) return true
  
  if (roles) {
    return loggedInUser.user.roles.some(role => roles.includes(role));
  }

  return true;
}
export const logout = callback => {
  window.localStorage.removeItem("authUser");
  window.localStorage.removeItem("token");
  callback();
}

