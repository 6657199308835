import React from "react";
import { Link, navigate } from "gatsby";
import "react-lazy-load-image-component/src/effects/blur.css";
import { isLoggedIn, logout } from "../../services/auth";
import Roles from "../../components/admin/roles";
import settings from "../../../settings";

class AdminLayout extends React.Component {
  state = {
    className: "dropdown-link",
  };

  mouseEnter = () => {
    this.setState({
      className: "dropdown-link active",
    });
  };

  mouseLeave = () => {
    this.setState({
      className: "dropdown-link",
    });
  };

  render() {
    const bmwPerformanceLogo = `${settings.IMAGES_BASE_URL}/images/logos/BMWPerformanceLogo.png`;
    return (
      <div className="admin-layout">
        <header>
          <div className="mobile__nav">
            <div className="container">
              <Link to="/" className="mobile-logo">
                <img src={bmwPerformanceLogo} alt="Logo" />
              </Link>
              <div
                className={`hamburger ${
                  this.state.menuIsOpen ? " " : "is-open"
                }`}
                onClick={this.handleMenuToggle}
                onKeyDown={this.handleMenuToggle}
                role="button"
                tabIndex={0}
                aria-label="hamburger"
              >
                <div className="hamburger__toggle" />
              </div>
            </div>
          </div>
          <nav className={`nav ${this.state.menuToggle ? " " : "hide-nav"}`}>
            <Link to="/" aria-label='logo'>
              <div className="logo">
                <img src={bmwPerformanceLogo} alt="Logo" />
              </div>
            </Link>
            <ul className="navbar">
              <div className="middle">
                {isLoggedIn([Roles.admin]) && (
                  <li>
                    <Link to="/admin/users">Users</Link>
                  </li>
                )}
                <li className="dropdown">
                  {isLoggedIn() &&
                    (isLoggedIn([Roles.bmwGroupEventEastCoast]) ||
                      isLoggedIn([Roles.bmwGroupEventWestCoast])) && (
                      <button className={this.state.className}>
                        Group Events
                      </button>
                    )}
                  <ul className="dropdown-menu">
                    {isLoggedIn([Roles.bmwGroupEventEastCoast]) && (
                      <li>
                        <Link
                          to="/admin/groupevents/eastcoast"
                          activeClassName="active"
                          className="dropdown-nav"
                          onMouseEnter={this.mouseEnter}
                        >
                          East Coast
                        </Link>
                      </li>
                    )}
                    {isLoggedIn([Roles.bmwGroupEventWestCoast]) && (
                      <li>
                        <Link
                          to="/admin/groupevents/westcoast"
                          activeClassName="active"
                          className="dropdown-nav"
                          onMouseEnter={this.mouseEnter}
                        >
                          West Coast
                        </Link>
                      </li>
                    )}
                    {isLoggedIn([Roles.bmwGroupEventIndianapolis]) && (
                      <li>
                        <Link
                          to="/admin/groupevents/indianapolis"
                          activeClassName="active"
                          className="dropdown-nav"
                          onMouseEnter={this.mouseEnter}
                        >
                          Indianapolis
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li className="dropdown">
                  {isLoggedIn() &&
                    (isLoggedIn([Roles.bmwGroupEventEastCoast]) ||
                      isLoggedIn([Roles.bmwGroupEventWestCoast])) && (
                      <button className={this.state.className}>
                        MINI Group Events
                      </button>
                    )}
                  <ul className="dropdown-menu">
                    {isLoggedIn([Roles.miniGroupEventWestCoast]) && (
                      <li>
                        <Link
                          to="/admin/minigroupevents/eastcoast"
                          activeClassName="active"
                          className="dropdown-nav"
                          onMouseEnter={this.mouseEnter}
                        >
                          East Coast
                        </Link>
                      </li>
                    )}
                    {isLoggedIn([Roles.miniGroupEventEastCoast]) && (
                      <li>
                        <Link
                          to="/admin/minigroupevents/westcoast"
                          activeClassName="active"
                          className="dropdown-nav"
                          onMouseEnter={this.mouseEnter}
                        >
                          West Coast
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                {isLoggedIn([Roles.westernRegion]) && (
                  <li>
                    <Link
                      to="/admin/westernregion-forms"
                      activeClassName="active"
                      className="dropdown-nav"
                    >
                      WesternRegion
                    </Link>
                  </li>
                )}
                {isLoggedIn([Roles.experiences]) && (
                  <li>
                    {" "}
                    <Link to="/admin/experiences-form" activeClassName="active">
                      Experiences
                    </Link>
                  </li>
                )}
                {isLoggedIn([Roles.experiences]) && (
                  <li>
                    <Link to="/admin/nurburgring" activeClassName="active">
                      NÜRBURGRING
                    </Link>
                  </li>
                )}
                <li className="dropdown">
                  {isLoggedIn() &&
                    (isLoggedIn([Roles.motoTwistedTour]) ||
                      isLoggedIn([Roles.motoAdventureTour]) ||
                      isLoggedIn([Roles.r18SmokyMountainsTour])) && (
                      <button className={this.state.className}>
                        Moto Tour
                      </button>
                    )}
                  <ul className="dropdown-menu">
                    {isLoggedIn([Roles.motoTwistedTour]) && (
                      <li>
                        <Link
                          to="/admin/mototour/twistedtour"
                          activeClassName="active"
                          className="dropdown-nav"
                          onMouseEnter={this.mouseEnter}
                        >
                          Twisted Tour
                        </Link>
                      </li>
                    )}
                    {isLoggedIn([Roles.motoAdventureTour]) && (
                      <li>
                        <Link
                          to="/admin/mototour/adventuretour"
                          activeClassName="active"
                          className="dropdown-nav"
                          onMouseEnter={this.mouseEnter}
                        >
                          Adventure Tour
                        </Link>
                      </li>
                    )}
                    {isLoggedIn([Roles.r18SmokyMountainsTour]) && (
                      <li>
                        <Link
                          to="/admin/mototour/r18smokymountainstour"
                          activeClassName="active"
                          className="dropdown-nav"
                          onMouseEnter={this.mouseEnter}
                        >
                          R18 Smoky Mountains Tour
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                <li className="dropdown">
                  {isLoggedIn() &&
                    (isLoggedIn([Roles.OffRoadTraining]) ||
                      isLoggedIn([Roles.OnRoadTraining])) && (
                      <button className={this.state.className}>
                        Private Trainings
                      </button>
                    )}
                  <ul className="dropdown-menu">
                    {isLoggedIn([Roles.OffRoadTraining]) && (
                      <li>
                        <Link
                          to="/admin/privatetraining/offroad"
                          activeClassName="active"
                          className="dropdown-nav"
                          onMouseEnter={this.mouseEnter}
                        >
                          Off-road Training
                        </Link>
                      </li>
                    )}
                    {isLoggedIn([Roles.OnRoadTraining]) && (
                      <li>
                        <Link
                          to="/admin/privatetraining/onroad"
                          activeClassName="active"
                          className="dropdown-nav"
                          onMouseEnter={this.mouseEnter}
                        >
                          On-road Training
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                {isLoggedIn([Roles.PlannerRegistrations]) && (
                  <li className="dropdown">
                    <Link to="/admin/plannerregistration/plannerregistrationtable2">
                      Planner Registration
                    </Link>
                  </li>
                )}
                <li className="dropdown">
                  {isLoggedIn() &&
                    (isLoggedIn([Roles.IndianapolisMotorSpeedway]) ||
                      isLoggedIn([Roles.VirginiaInternationalRaceway]) ||
                      isLoggedIn([Roles.Other])) && (
                      <button className={this.state.className}>
                        M School On-Tour Events
                      </button>
                    )}
                  <ul className="dropdown-menu">
                    {isLoggedIn([Roles.IndianapolisMotorSpeedway]) && (
                      <li>
                        <Link
                          to="/admin/mschoolontourevents/indianapolismotorspeedway"
                          activeClassName="active"
                          className="dropdown-nav"
                          onMouseEnter={this.mouseEnter}
                        >
                          Indianapolis Motor Speedway
                        </Link>
                      </li>
                    )}
                    {isLoggedIn([Roles.VirginiaInternationalRaceway]) && (
                      <li>
                        <Link
                          to="/admin/mschoolontourevents/virginiainternationalraceway"
                          activeClassName="active"
                          className="dropdown-nav"
                          onMouseEnter={this.mouseEnter}
                        >
                          Virginia International Raceway
                        </Link>
                      </li>
                    )}
                    {isLoggedIn([Roles.Other]) && (
                      <li>
                        <Link
                          to="/admin/mschoolontourevents/other"
                          activeClassName="active"
                          className="dropdown-nav"
                          onMouseEnter={this.mouseEnter}
                        >
                          Other
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
                {isLoggedIn([Roles.press]) && (
                  <li>
                    <Link to="/admin/press-inquiry" activeClassName="active">
                      Press Inquiry
                    </Link>
                  </li>
                )}
                {isLoggedIn([Roles.admin]) && (
                  <li>
                    <Link to="/admin/pga-west" activeClassName="active">
                      PGA West
                    </Link>
                  </li>
                )}
                  {isLoggedIn([Roles.admin]) && (
                  <li>
                    <Link to="/admin/laas-contest" activeClassName="active">
                      LAAS Contest
                    </Link>
                  </li>
                )}
                {isLoggedIn() && (
                  <li>
                    <a
                      href="/"
                      onClick={(event) => {
                        event.preventDefault();
                        logout(() => navigate(`/admin/login`));
                      }}
                    >
                      Logout
                    </a>
                  </li>
                )}
              </div>
            </ul>
          </nav>
        </header>
        <main>{this.props.children}</main>
        <footer>
          <div className="copyright">
            <span>
              © 2023 BMW of North America, LLC. The BMW name, model names and
              logo are registered trademarks.
            </span>
          </div>
        </footer>
      </div>
    );
  }
}

export default AdminLayout;
