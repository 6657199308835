import React from 'react';
import AXIOS from '../../../axios-config';
import Moment from 'react-moment';
import AdminLayout from './adminlayout'
import { showErrorToast } from "../../helpers/toast";


class MiniGroupEventDetail extends React.Component {
  state = {
    submissions: []
  };

  componentDidMount() {
    
    const id = this.props.id;
  
    AXIOS.get(`/api/mini-groupevent/${id}`)
      .then((response) => {
        this.setState({
          submissions: response.data
        });
      })
      .catch((err) => {
        showErrorToast(err)
      });
  }

  render() {
    const { firstname, lastname, organization, job_title, company_website, email, phone, city, state, address, zipcode, event_type, event_date, attendees, notes, date, _id, selectedLocation } = this.state.submissions;
    return (
      <AdminLayout>
        <div className="submission">
        <div className="container">
          <h1> Mini Group Event Form Submission (ID - {_id})</h1>
          <div className="submission__content">
            <table className="form-submission">
              <thead>
                <tr>
                  <th>Fields</th>
                  <th>Information</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Date</td>
                  <td>
                    <Moment format="YYYY-MM-DD HH:mm">{date}</Moment>
                  </td>
                </tr>
                <tr>
                  <td>First Name</td>
                  <td>{firstname}</td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>{lastname}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{email}</td>
                </tr>
                <tr>
                  <td>Organization</td>
                  <td>{organization}</td>
                </tr>
                <tr>
                  <td>Job Title</td>
                  <td>{job_title}</td>
                </tr>
                <tr>
                  <td>Company Website</td>
                  <td>{company_website}</td>
                </tr>
                <tr>
                  <td>Phone Number</td>
                  <td>{phone}</td>
                </tr>
                <tr>
                  <td>address</td>
                  <td>{address}</td>
                </tr>
                <tr>
                  <td>City</td>
                  <td>{city}</td>
                </tr>
                <tr>
                  <td>State</td>
                  <td>{state}</td>
                </tr>
                <tr>
                  <td>Zipcode</td>
                  <td>{zipcode}</td>
                </tr>
                <tr>
                  <td>Event Date</td>
                  <td>{event_date}</td>
                </tr>
                <tr>
                  <td>Event Type</td>
                  <td>{event_type}</td>
                </tr>
                <tr>
                  <td>Number of Attendees</td>
                  <td>{attendees}</td>
                </tr>
                <tr>
                  <td>Selected Location</td>
                  <td>{selectedLocation}</td>
                </tr>
                <tr>
                  <td>Notes</td>
                  <td>{notes}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </AdminLayout>
    );
  }
}

export default MiniGroupEventDetail;
