import React from "react"
import { navigate } from "gatsby"
import { isLoggedIn, logout } from "../../services/auth"

const PrivateRoute = ({ component: Component, location, roles, ...rest }) => {
  if (!isLoggedIn(roles) && (location.pathname !== `/admin/login` || location.pathname === '/admin/register')) {
    logout(() =>navigate("/admin/login"));
    return null
  }


  return <Component {...rest} />
}

export default PrivateRoute