import React from 'react';
import AXIOS from '../../../axios-config';
import Moment from 'react-moment';
import AdminLayout from './adminlayout'
import { showErrorToast } from "../../helpers/toast";


class MSchoolOnTourEventDetail extends React.Component {
  state = {
    submissions: []
  };

  componentDidMount() {

    const id = this.props.id;

    AXIOS.get(`/api/mschool-ontour-form/${id}`)
      .then((response) => {
        this.setState({
          submissions: response.data
        });
      })
      .catch((err) => {
        showErrorToast(err);
      });
  }

  render() {
    const { fullname, email, date, _id, selectedTracks } = this.state.submissions;
    return (
      <AdminLayout>
        <div className="submission">
        <div className="container">
          <h1> Private Training Session Submission (ID - {_id})</h1>
          <div className="submission__content">
            <table className="form-submission">
              <thead>
                <tr>
                  <th>Fields</th>
                  <th>Information</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Date</td>
                  <td>
                    <Moment format="YYYY-MM-DD HH:mm">{date}</Moment>
                  </td>
                </tr>
                <tr>
                  <td>Full Name</td>
                  <td>{fullname}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{email}</td>
                </tr>
                <tr>
                  <td>Selected Tracks</td>
                  <td>{selectedTracks}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </AdminLayout>
    );
  }
}

export default MSchoolOnTourEventDetail;
