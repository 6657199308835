let activeEnv =
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development"

// require("dotenv").config({
//   path: `.env.${activeEnv}`,
// })

console.log(`RUNNING ${activeEnv}`);
let settings = {
  //Front
  ACTIVE_ENV: activeEnv,
  API_URL: activeEnv === "production" ? "https://bmwperformancecenter.com" : activeEnv === "staging" ? "https://bmwpc.staging.dotnetfactory.com" : activeEnv === "development" ? "http://localhost:5000" : "http://localhost:5000",
  IMAGES_BASE_URL: "https://bmwpc.nyc3.cdn.digitaloceanspaces.com",
  GOOGLE_API_KEY: 'AIzaSyAR8Ai9tGw03j_5ZbvZnsINIzbMw5284yg',
  YOUTUBE_CHANNEL_ID: 'UC00pxApQ5mWAPNaL3idBAmg',
  GOOGLE_TRACKING_ID: `UA-${activeEnv === 'production' ? '84796420-1' : activeEnv}`,
  REACT_APP_SITE_KEY: '6Lfh8xoqAAAAAORg7dZv1cbQlavn_witJz9-ptdo',
  RECAPTCHA_SECRET_KEY: '6Lfh8xoqAAAAAEvevLaGmgT4fKPzobjFoQg8zkSt',

  //Back
  CLIENT_URL: process.env.CLIENT_URL,
  API_PORT: process.env.API_PORT,
  WESTERN_REGION_RECIPIENTS: process.env.WESTERN_REGION_RECIPIENTS,
  MINI_THERMAL_RECIPIENTS: process.env.MINI_THERMAL_RECIPIENTS,
  MINI_SPARTANBURG_RECIPIENTS: process.env.MINI_SPARTANBURG_RECIPIENTS,
  BMW_THERMAL_RECIPIENTS: process.env.BMW_THERMAL_RECIPIENTS,
  BMW_ADVANCED_MSCHOOOL_ON_TOUR: process.env.BMW_ADVANCED_MSCHOOOL_ON_TOUR,
  BMW_MPACT_RECIPIENTS: process.env.BMW_MPACT_RECIPIENTS,
  BMW_SOUTHOCCC_RECIPIENTS: process.env.BMW_SOUTHOCCC_RECIPIENTS,
  BMW_NURBURGRING_RECIPIENTS: process.env.BMW_NURBURGRING_RECIPIENTS,
  PLANNER_REGISTRATION_RECIPIENTS: process.env.PLANNER_REGISTRATION_RECIPIENTS,
  CAR_AND_COOKOUT_RECIPIENTS: process.env.CAR_AND_COOKOUT_RECIPIENTS,
  MOTO_RECIPIENTS: process.env.MOTO_RECIPIENTS,
  MINI_RECIPIENTS: process.env.MINI_RECIPIENTS,
  PGA_WEST_RECIPIENTS: process.env.PGA_WEST_RECIPIENTS,
  BMW_SPARTANBURG_RECIPIENTS: process.env.BMW_SPARTANBURG_RECIPIENTS,
  EXCURSION_RECIPIENTS: process.env.EXCURSION_RECIPIENTS,
  PRESS_RECIPIENTS: process.env.PRESS_RECIPIENTS,
  PRIVATE_MOTO_RECIPIENTS: process.env.PRIVATE_MOTO_RECIPIENTS,
  PRESS_INQUIRY_PC_EAST_AND_WEST_RECIPIENTS: process.env.PRESS_INQUIRY_PC_EAST_AND_WEST_RECIPIENTS,
  PRESS_INQUIRY_BMW_RECIPIENTS: process.env.PRESS_INQUIRY_BMW_RECIPIENTS,
  PRESS_INQUIRY_MINI_RECIPIENTS: process.env.PRESS_INQUIRY_MINI_RECIPIENTS,
  PRESS_INQUIRY_US_RIDER_ACADEMY_RECIPIENTS: process.env.PRESS_INQUIRY_US_RIDER_ACADEMY_RECIPIENTS,
  PRESS_INQUIRY_BMW_DELIVERY_RECIPIENTS: process.env.PRESS_INQUIRY_BMW_DELIVERY_RECIPIENTS,
  PRESS_INQUIRY_RACE_LICENSING_PROGRAM_RECIPIENTS: process.env.PRESS_INQUIRY_RACE_LICENSING_PROGRAM_RECIPIENTS,
  DB_CONNECTION_STRING: process.env.DB_CONNECTION_STRING,
  BMW_EMAIL_USER: process.env.BMW_EMAIL_USER,
  BMW_EMAIL_PASSWORD: process.env.BMW_EMAIL_PASSWORD,
  BMW_FROM_EMAIL: process.env.BMW_FROM_EMAIL,
  SMTP_HOST: process.env.SMTP_HOST,
  SMTP_PORT: process.env.SMTP_PORT,
  GOOGLE_TAG_ID: process.env.GOOGLE_TAG_ID,
}

module.exports = settings;
